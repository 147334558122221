import React from 'react';
import s from './SkeletonSignInComponent.module.scss';

const SkeletonSignInComponent = () => {
  return (
    <div className={s.skeletonWrapper}>
      <div className={s.skeletonContainer}>
        <div className={s.skeletonItemLarge}></div>
        <div className={s.skeletonItems}>
          <div className={s.skeletonItemSmall}></div>
          <div className={s.skeletonItem}></div>
        </div>
        <div className={s.skeletonItems}>
          <div className={s.skeletonItemSmall}></div>
          <div className={s.skeletonItem}></div>
        </div>
        <div className={s.skeletonItemLargeFooter}></div>
      </div>
    </div>
  );
};

export default SkeletonSignInComponent;
