import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Dialog,
  DialogResponse,
  DialogPagination,
  DialogStats,
  StatsQuery,
  ChronologyMessagesReactionStats,
  MessagesReactionStats,
  ChronologyMessagesCountStats,
} from './DialogsTypes';
import { ErrorResponse } from 'store/ReduxTypes';
import axios, { AxiosError } from 'axios';
import { refreshTokenOperation } from '../auth/auth-operations';
import { AppDispatch } from 'store/store';

async function requestWithAuth<T, RejectedValueType>(
  dispatch: AppDispatch,
  requestFn: () => Promise<T>,
  rejectWithValue: (value: ErrorResponse) => RejectedValueType,
): Promise<T | ReturnType<typeof rejectWithValue>> {
  try {
    return await requestFn();
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    if (axios.isAxiosError(axiosError) && axiosError.response?.status === 401) {
      const refreshAction = await dispatch(refreshTokenOperation());
      if (refreshAction.meta.requestStatus === 'fulfilled') {
        return await requestFn();
      }
    }
    return rejectWithValue({
      message: axiosError.message || 'Request failed',
    });
  }
}

// Операция для получения диалогов с фильтрацией и пагинацией
const getDialogsOperation = createAsyncThunk<
  DialogResponse,
  DialogPagination,
  { rejectValue: ErrorResponse }
>('dialogs/getDialogs', async (paginationParams, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () =>
      axios
        .get<DialogResponse>('/v1/dialogs', {
          params: {
            page: paginationParams.page,
            limit: paginationParams.limit,
            from: paginationParams.from,
            to: paginationParams.to,
            marker: paginationParams.marker,
            sent: paginationParams.sent,
            reviewed: paginationParams.reviewed,
          },
        })
        .then(res => res.data),
    rejectWithValue,
  );
});

// Операция для получения статистики диалогов
const getDialogStatsOperation = createAsyncThunk<
  DialogStats,
  StatsQuery,
  { rejectValue: ErrorResponse }
>('dialogs/getStats', async (queryParams, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () =>
      axios
        .get<DialogStats>('/v1/statistics/states', {
          params: {
            year: queryParams.year,
            month: queryParams.month,
            forWeek: queryParams.forWeek,
          },
        })
        .then(res => res.data),
    rejectWithValue,
  );
});

// Операция для отправки диалога на проверку
const sendDialogForReviewOperation = createAsyncThunk<
  string,
  string,
  { rejectValue: ErrorResponse }
>('dialogs/sendForReview', async (dialogId, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () => axios.post(`/v1/dialogs/review`, { id: dialogId }).then(() => dialogId),
    rejectWithValue,
  );
});

// Операция для получения случайных диалогов за неделю
const getRandomWeeklyDialogsOperation = createAsyncThunk<
  Dialog[],
  void,
  { rejectValue: ErrorResponse }
>('dialogs/getRandomWeekly', async (_, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () => axios.get<Dialog[]>('/v1/dialogs/random-week').then(res => res.data),
    rejectWithValue,
  );
});

// Операция для получения общей статистики реакций по всем сообщениям с фильтрацией
const getTotalReactionsStatsOperation = createAsyncThunk<
  MessagesReactionStats,
  StatsQuery,
  { rejectValue: ErrorResponse }
>('dialogs/getTotalReactionsStats', async (queryParams, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () =>
      axios
        .get<MessagesReactionStats>('/v1/statistics/reactions', {
          params: {
            year: queryParams.year,
            month: queryParams.month,
            forWeek: queryParams.forWeek,
          },
        })
        .then(res => res.data),
    rejectWithValue,
  );
});

// Операция для получения статистики реакций по дням с фильтрацией
const getReactionsStatsByDayOperation = createAsyncThunk<
  ChronologyMessagesReactionStats[],
  StatsQuery,
  { rejectValue: ErrorResponse }
>('dialogs/getReactionsStatsByDay', async (queryParams, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () =>
      axios
        .get<ChronologyMessagesReactionStats[]>('/v1/statistics/reactions-by-day', {
          params: {
            year: queryParams.year,
            month: queryParams.month,
            forWeek: queryParams.forWeek,
          },
        })
        .then(res => res.data),
    rejectWithValue,
  );
});

// Операция для получения статистики сообщений по дням с фильтрацией
const getMessagesStatsByDayOperation = createAsyncThunk<
  ChronologyMessagesCountStats[],
  StatsQuery,
  { rejectValue: ErrorResponse }
>('dialogs/getMessagesStatsByDayOperation', async (queryParams, { dispatch, rejectWithValue }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () =>
      axios
        .get<ChronologyMessagesCountStats[]>('/v1/statistics/messages-by-day', {
          params: {
            year: queryParams.year,
            month: queryParams.month,
            forWeek: queryParams.forWeek,
          },
        })
        .then(res => res.data),
    rejectWithValue,
  );
});

export {
  getDialogsOperation,
  sendDialogForReviewOperation,
  getRandomWeeklyDialogsOperation,
  getDialogStatsOperation,
  getTotalReactionsStatsOperation,
  getReactionsStatsByDayOperation,
  getMessagesStatsByDayOperation,
};
