// Defining the structure for the routes object using TypeScript interface.
interface RoutePaths {
  signIn: string;
  signUp: string;
  dialogs: string;
  markers: string;
  statistics: string;
}

// Defining and exporting the ROUTES constant with explicit paths for each route in the application.
// These paths are used throughout the application to navigate between different views and components.
export const ROUTES: RoutePaths = {
  signIn: '/signin', // Path used for the sign-in page.
  signUp: '/signup', // Path used for the sign-up page.
  dialogs: '/', // Path used for the home page.
  markers: '/markers', // Path used for the markers page.
  statistics: '/statistics', // Path used for the statistics page.
};
