import React from 'react';
import s from './SkeletonMarkersComponent.module.scss';

const SkeletonMarkersComponent = () => {
  return (
    <div className={s.skeletonWrapper}>
      <div className={s.skeletonContainer}>
        <div className={s.skeletonHeader}>
          <div className={s.skeletonHeaderItemsSmall}>
            <div className={`${s.skeletonHeaderItemSmall} ${s.skeletonHeaderItemSmallAdd}`}></div>
            <div className={s.skeletonHeaderItemSmall}></div>
            <div className={s.skeletonHeaderItemSmall}></div>
          </div>
          <div className={s.skeletonHeaderItemLarge}></div>
        </div>
        <div className={s.skeletonBody}>
          <div className={s.skeletonRow}>
            <div className={s.skeletonRowSmall}></div>
          </div>
          <div className={s.skeletonItems}>
            <div className={s.skeletonItem}></div>
            <div className={s.skeletonItem}></div>
            <div className={s.skeletonItem}></div>
          </div>
          <div className={s.skeletonItemsRowFooter}>
            <div className={s.skeletonItemLargeFooter}></div>
            <div className={s.skeletonItemLargeFooter}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonMarkersComponent;
