import axios, { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthResponse, SignInValues, SignUpValues, User } from './AuthTypes';
import { ErrorResponse } from 'store/ReduxTypes';

const signUpOperation = createAsyncThunk<void, SignUpValues, { rejectValue: ErrorResponse }>(
  'auth/signUp',
  async (values, { rejectWithValue }) => {
    try {
      await axios.post('/v1/auth/sign-up', {
        email: values.email,
        password: values.password,
        name: values.name,
        key: values.accessKey,
      });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue({ message: axiosError.message || 'Регистрация не удалась' });
    }
  },
);

const signInOperation = createAsyncThunk<
  AuthResponse,
  SignInValues,
  { rejectValue: ErrorResponse }
>('auth/signIn', async (values, { rejectWithValue }) => {
  try {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', values.email);
    params.append('password', values.password);

    const res = await axios.post<AuthResponse>('/v1/auth/sign-in', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const { accessToken } = res.data;
    return { accessToken };
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    // Проверяем код ошибки и возвращаем фиксированное сообщение
    const errorMessage =
      axiosError.response?.status === 400
        ? 'Неверный логин или пароль'
        : axiosError.message || 'Не удалось выполнить вход';

    return rejectWithValue({ message: errorMessage });
  }
});

const signOutOperation = createAsyncThunk<void, void, { rejectValue: ErrorResponse }>(
  'auth/signOut',
  async (_, { rejectWithValue }) => {
    try {
      await axios.get('/v1/auth/sign-out');
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue({ message: axiosError.message || 'Failed to sign-in' });
    }
  },
);
const refreshTokenOperation = createAsyncThunk<AuthResponse, void, { rejectValue: ErrorResponse }>(
  'auth/refresh',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get<AuthResponse>('/v1/auth/refresh-token');
      const { accessToken } = res.data;
      return { accessToken };
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue({ message: axiosError.message || 'Token refresh failed' });
    }
  },
);

// Redefine getUserOperation with additional refresh logic on 401 error
const getUserOperation = createAsyncThunk<User, void, { rejectValue: ErrorResponse }>(
  'auth/user',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get<User>('/v1/auth/user');
      // const res = {
      //   data: {
      //     name: 'John Doe',
      //     email: '9Vx4I@example.com',
      //   }
      // }
      return res.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axios.isAxiosError(axiosError) && axiosError.response?.status === 401) {
        // Attempt to refresh the token
        const refreshAction = await dispatch(refreshTokenOperation());
        if (refreshAction.meta.requestStatus === 'fulfilled') {
          // Retry getUserOperation if the token refresh was successful
          const userResponse = await axios.get<User>('/v1/auth/user');
          return userResponse.data;
        }
      }
      // If error is not due to 401, reject with the original error
      return rejectWithValue({ message: axiosError.message || 'Failed to fetch user' });
    }
  },
);

export {
  signUpOperation,
  signInOperation,
  signOutOperation,
  refreshTokenOperation,
  getUserOperation,
};
