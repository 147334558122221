import React, { useState } from 'react';
import SvgIcon from 'components/shared/icons/SvgIcon';
import s from './FallbackComponent.module.scss';

interface FallbackProps {
  errorMessage?: string;
}

const FallbackComponent: React.FC<FallbackProps> = ({ errorMessage }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={s.fallbackBackground}>
      <div className={s.fallbackContainer}>
        <div className={s.alarmIconContainer}>
          <SvgIcon className={s.alarmIcon} name="icon-alarm" />
        </div>
        <h2 className={s.fallbackTitle}>Ошибка</h2>
        <div className={s.detailsToggle} onClick={toggleDetails}>
          <span>Детальнее</span>
          <SvgIcon className={`${s.arrowIcon} ${isExpanded ? s.expanded : ''}`} name="icon-arrow" />
        </div>
        {isExpanded && (
          <div className={s.fallbackMessage}>
            <p>{errorMessage || 'Неизвестная ошибка'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FallbackComponent;
