import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDialogsOperation,
  getDialogStatsOperation,
  getMessagesStatsByDayOperation,
  getReactionsStatsByDayOperation,
  getTotalReactionsStatsOperation,
  sendDialogForReviewOperation,
} from './dialogs-operations';
import {
  DialogsState,
  DialogResponse,
  DialogStats,
  MessagesReactionStats,
  ChronologyMessagesReactionStats,
  ChronologyMessagesCountStats,
} from './DialogsTypes';

const initialState: DialogsState = {
  dialogs: [],
  total: 0, // Общее количество страниц
  page: 1, // Текущая страница
  markersStats: null, // Изначально статистика отсутствует
  reactionsStats: null,
  reactionsStatsByDay: null,
  messagesStatsByDay: null,
  error: null,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getDialogsOperation.fulfilled,
      (state, action: PayloadAction<DialogResponse>) => {
        state.dialogs = action.payload.data;
        state.total = action.payload.total;
        state.page = action.payload.page;
      },
    );

    builder.addCase(getDialogsOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to fetch dialogs';
    });

    // Обработка успешного получения статистики
    builder.addCase(
      getDialogStatsOperation.fulfilled,
      (state, action: PayloadAction<DialogStats>) => {
        state.markersStats = action.payload;
      },
    );

    builder.addCase(getDialogStatsOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to fetch dialog stats';
    });

    // Обработка успешной отправки на ревью
    builder.addCase(
      sendDialogForReviewOperation.fulfilled,
      (state, action: PayloadAction<string>) => {
        const index = state.dialogs.findIndex(dialog => dialog.id === action.payload);
        if (index !== -1) {
          state.dialogs[index] = {
            ...state.dialogs[index],
            state: { ...state.dialogs[index].state, sent: true },
          };
        }
      },
    );

    builder.addCase(sendDialogForReviewOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to send dialog for review';
    });

    builder.addCase(
      getTotalReactionsStatsOperation.fulfilled,
      (state, action: PayloadAction<MessagesReactionStats>) => {
        state.reactionsStats = action.payload;
      },
    );
    builder.addCase(getTotalReactionsStatsOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to fetch total reactions stats';
    });

    // Обработка успешного получения статистики реакций по дням
    builder.addCase(
      getReactionsStatsByDayOperation.fulfilled,
      (state, action: PayloadAction<ChronologyMessagesReactionStats[]>) => {
        state.reactionsStatsByDay = action.payload;
      },
    );
    builder.addCase(getReactionsStatsByDayOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to fetch reactions stats by day';
    });

    // Обработка успешного получения статистики сообщений по дням
    builder.addCase(
      getMessagesStatsByDayOperation.fulfilled,
      (state, action: PayloadAction<ChronologyMessagesCountStats[]>) => {
        state.messagesStatsByDay = action.payload;
      },
    );
    builder.addCase(getMessagesStatsByDayOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to fetch reactions stats by day';
    });
  },
});

export default dialogsSlice.reducer;
