import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorResponse } from 'store/ReduxTypes';
import axios, { AxiosError } from 'axios';
import { Marker, MarkerSentData, MarkersPagination, MarkersResponse } from './MarkersTypes';
import { refreshTokenOperation } from '../auth/auth-operations';
import { AppDispatch } from 'store/store';

// Универсальная функция для запросов с проверкой на 401 статус
async function requestWithAuth<T, RejectedValueType>(
  dispatch: AppDispatch,
  requestFn: () => Promise<T>,
  rejectWithValue: (value: ErrorResponse) => RejectedValueType,
): Promise<T | ReturnType<typeof rejectWithValue>> {
  try {
    return await requestFn();
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    if (axios.isAxiosError(axiosError) && axiosError.response?.status === 401) {
      const refreshAction = await dispatch(refreshTokenOperation());
      if (refreshAction.meta.requestStatus === 'fulfilled') {
        return await requestFn();
      }
    }
    return rejectWithValue({
      message: axiosError.message || 'Request failed',
    });
  }
}

// Операция для получения всех маркеров с пагинацией
const getAllMarkersOperation = createAsyncThunk<
  MarkersResponse,
  MarkersPagination,
  { rejectValue: ErrorResponse }
>('markers/getAll', async (paginationParams, { rejectWithValue, dispatch }) => {
  return await requestWithAuth(
    dispatch as AppDispatch,
    () =>
      axios
        .get<MarkersResponse>(`/v1/markers`, {
          params: {
            page: paginationParams.page,
            limit: paginationParams.limit,
          },
        })
        .then(res => res.data),
    rejectWithValue,
  );
});

// Операция для добавления нового маркера
const addMarkerOperation = createAsyncThunk<Marker, MarkerSentData, { rejectValue: ErrorResponse }>(
  'markers/add-marker',
  async (markerSentData, { rejectWithValue, dispatch }) => {
    return await requestWithAuth(
      dispatch as AppDispatch,
      () => axios.post<Marker>('/v1/markers', markerSentData).then(res => res.data),
      rejectWithValue,
    );
  },
);

// Операция для удаления маркера
const deleteMarkerOperation = createAsyncThunk<string, string, { rejectValue: ErrorResponse }>(
  'markers/delete-marker',
  async (markerId, { rejectWithValue, dispatch }) => {
    return await requestWithAuth(
      dispatch as AppDispatch,
      () => axios.delete(`/v1/markers/${markerId}`).then(() => markerId),
      rejectWithValue,
    );
  },
);

// Операция для обновления маркера
const updateMarkerOperation = createAsyncThunk<Marker, Marker, { rejectValue: ErrorResponse }>(
  'markers/update-marker',
  async (markerSentData, { rejectWithValue, dispatch }) => {
    return await requestWithAuth(
      dispatch as AppDispatch,
      () => axios.put<Marker>('/v1/markers', markerSentData).then(res => res.data),
      rejectWithValue,
    );
  },
);

export { getAllMarkersOperation, addMarkerOperation, deleteMarkerOperation, updateMarkerOperation };
