import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllMarkersOperation,
  addMarkerOperation,
  deleteMarkerOperation,
  updateMarkerOperation,
} from './markers-operations';
import { MarkersState, Marker, MarkersResponse } from './MarkersTypes';

const initialState: MarkersState = {
  markers: [],
  total: 0,
  page: 1,
  error: null,
};

const markersSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Handle fetching markers
    builder.addCase(
      getAllMarkersOperation.fulfilled,
      (state, action: PayloadAction<MarkersResponse>) => {
        state.markers = action.payload.data;
        state.total = action.payload.total;
        state.page = action.payload.page;
      },
    );

    builder.addCase(getAllMarkersOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to fetch markers';
    });

    // Handle adding a marker
    builder.addCase(addMarkerOperation.fulfilled, (state, action: PayloadAction<Marker>) => {
      state.markers = [...state.markers, action.payload];
    });

    builder.addCase(addMarkerOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to add marker';
    });

    // Handle deleting a marker
    builder.addCase(deleteMarkerOperation.fulfilled, (state, action: PayloadAction<string>) => {
      state.markers = state.markers.filter(marker => marker.id !== action.payload);
    });

    builder.addCase(deleteMarkerOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to remove marker';
    });

    // Handle updating a marker
    builder.addCase(updateMarkerOperation.fulfilled, (state, action: PayloadAction<Marker>) => {
      const index = state.markers.findIndex(marker => marker.id === action.payload.id);
      if (index !== -1) {
        state.markers[index] = action.payload;
      }
    });

    builder.addCase(updateMarkerOperation.rejected, (state, action) => {
      state.error = action.payload?.message || 'Failed to update marker';
    });
  },
});

export default markersSlice.reducer;
