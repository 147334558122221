import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// Hooks
import { useAPIConfig } from './hooks/useAPIConfig'; // Configuration for database connection

// Constants
import { ROUTES } from './configs/routeConstants'; // Route paths defined in a constants file for maintainability

// Route-related components
import ProtectedRoute from './components/routes/ProtectedRoute'; // Component for protected routes (auth required)
import PublicRoute from './components/routes/PublicRoute'; // Component for public routes (no auth required)

// Redux-related imports
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn } from 'store/auth/auth-selectors'; // Selector to check if the user is logged in
import { getUserOperation } from 'store/auth/auth-operations'; // Operation to fetch user details
import { AppDispatch } from 'store/store'; // Type definition for the Redux dispatch function

// Error handling
import ErrorBoundary from 'utils/ErrorBoundary'; // Component to catch and handle errors
import SkeletonDialogsComponent from 'components/widgets/dialogs/skeletonDialogsComponent/SkeletonDialogsComponent';
import SkeletonMarkersComponent from 'components/widgets/markers/skeletonMarkersComponent/SkeletonMarkersComponent';
import SkeletonSignInComponent from 'components/widgets/signIn/skeletonSignInComponent/SkeletonSignInComponent';
import SkeletonSignUpComponent from 'components/widgets/signUp/skeletonSignUpComponent/SkeletonSignUpComponent';
import SkeletonStatisticsComponent from 'components/widgets/statistics/skeletonStatisticsComponent/SkeletonStatisticsComponent';

// Lazy-loaded components for routes
const SignInPage = lazy(() => import('./views/signInPage/SignInPage'));
const SignUpPage = lazy(() => import('./views/signUpPage/SignUpPage'));
const DialogsPage = lazy(() => import('./views/dialogsPage/DialogsPage'));
const MarkersPage = lazy(() => import('./views/markersPage/MarkersPage'));
const StatisticsPage = lazy(() => import('./views/statisticsPage/StatisticsPage'));

function App() {
  const dispatch: AppDispatch = useDispatch(); // Hook to dispatch actions in Redux
  const isLoggedIn = useSelector(getIsLoggedIn); // Hook to get the logged-in status from Redux store

  // Initializes database configuration on app start-up
  useAPIConfig();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserOperation());
    }
  }, [isLoggedIn, dispatch]);

  // Render the main app structure with routing and error boundaries
  return (
    <>
      <ErrorBoundary>
        <Routes>
          <Route
            path={ROUTES.signIn}
            element={
              <PublicRoute
                element={
                  <Suspense fallback={<SkeletonSignInComponent />}>
                    <SignInPage />
                  </Suspense>
                }
                redirectTo={ROUTES.dialogs}
                restricted
              />
            }
          />
          <Route
            path={ROUTES.signUp}
            element={
              <PublicRoute
                element={
                  <Suspense fallback={<SkeletonSignUpComponent />}>
                    <SignUpPage />
                  </Suspense>
                }
                redirectTo={ROUTES.dialogs}
                restricted
              />
            }
          />
          <Route
            path={ROUTES.dialogs}
            element={
              <ProtectedRoute
                element={
                  <Suspense fallback={<SkeletonDialogsComponent />}>
                    <DialogsPage />
                  </Suspense>
                }
                redirectTo={ROUTES.signIn}
              />
            }
          />
          <Route
            path={ROUTES.markers}
            element={
              <ProtectedRoute
                element={
                  <Suspense fallback={<SkeletonMarkersComponent />}>
                    <MarkersPage />
                  </Suspense>
                }
                redirectTo={ROUTES.signIn}
              />
            }
          />
          <Route
            path={ROUTES.statistics}
            element={
              <ProtectedRoute
                element={
                  <Suspense fallback={<SkeletonStatisticsComponent />}>
                    <StatisticsPage />
                  </Suspense>
                }
                redirectTo={ROUTES.signIn}
              />
            }
          />
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default App;
